/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2010-2019南京十二梦信息技术有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2019年08月25日</li>
 * <li>@author     : ____′↘夏悸</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import manba from 'manba';
import {toClean} from "number-string";
import {isNumber} from "xe-utils";
import {confirm} from "heyui.ext";
import store from "@/store";
import {DxMoney, ToMoney, ToNumNumber} from "@js/common/utils";

const FormatNum = function (f, digit = 2) {
  let m = Math.pow(10, digit);
  let des = f * m + 0.5;
  return parseInt(des, 10) / m;
};

export default {
  install: (vue) => {
    vue.config.globalProperties.dxMoney = (number) => {
      return DxMoney(number);
    }

    vue.config.globalProperties.ToMoney = (number) => {
      return ToMoney(number);
    }

    vue.config.globalProperties.fqFormat = (n = '') => {
      if (!n) {
        return ''
      }
      if (n.indexOf('年') !== -1) {
        return n;
      }
      return manba(n).format("YYYY年MM期");
    };

    vue.config.globalProperties.dFormat = (n, format) => {
      if (!n) {
        return ''
      }
      return manba(n).format(format);
    };

    vue.config.globalProperties.moneyFormat = (n) => {
      if (!n) {
        return '0'
      }
      let num = Math.abs(n);
      if (num < 10000) {
        return n;
      } else if (num >= 10000 && num < 100000000) {
        return FormatNum(n / 10000) + "万";
      } else {
        return FormatNum(n / 100000000, 4) + "亿";
      }
    };

    vue.config.globalProperties.numFormat = (number, dft = '', maxPrecision = 2) => {
      if (!isNumber(number) || !number) {
        return dft || ''
      }
      return toClean(number, {maxPrecision: maxPrecision, minPrecision: 2});
    };

    vue.config.globalProperties.ToNumNumber = (number) => {
      return ToNumNumber(number);
    };

    vue.config.globalProperties.isChecked = (voucherDate) => {
      if (voucherDate) {
        const vd = manba(voucherDate);
        const checkout = store.getters.checkoutList.find((val) => val.checkYear === vd.year() && val.checkMonth === vd.month());
        if (checkout) {
          return checkout.status === 2;
        }
      }
      return true;
    };

    vue.config.globalProperties.SaCheckPermission = (value) => {
      const {roleInfo, authorities} = store.getters
      if (typeof value === 'string') {
        value = [value];
      }
      //账套管理员
      return !(!roleInfo.astAdmin && !value.every(val => authorities.includes(val)));
    };

    vue.config.globalProperties.$Confirm = (content) => {
      return new Promise((resolve, reject) => {
        confirm({
          title: "操作提示",
          content,
          onConfirm: resolve,
          onCancel: reject
        })
      })
    };

    vue.config.globalProperties.FormatFileSize = (size, pointLength = 2, units = ['B', 'K', 'M', 'G', 'TB']) => {
      let unit;
      while ((unit = units.shift()) && size > 1024) {
        size = size / 1024;
      }
      return (unit === 'B' ? size : size.toFixed(pointLength)) + unit;
    }
  }
}
